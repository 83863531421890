import { Shortlink } from "../models/shared/Shortlink";
import { useEmulators } from "./firebase";
import { DEFAULT_DOMAIN } from "./shared/constants";

export const getRemainingTrialDays = (trialEndsAt: Date): number => {
    const now = new Date();
    const diff = trialEndsAt.getTime() - now.getTime();
    return Math.ceil(diff / (1000 * 3600 * 24));
}


const adjectives = [
    'hyperlinked', 'witty', 'clickworthy', 'hilarious', 'laughing',
    'linktastic', 'humorclick', 'giggleworthy', 'quirky', 'amusing',
];

const nouns = [
    'linkster', 'clickopotamus', 'linkosaur', 'laughfrog', 'gigglebunny',
    'linkosaurus', 'jokicorn', 'snickerdoodle', 'chucklephoenix', 'funnylinks',
];


export const generateInitName = (): string => {
    const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const noun = nouns[Math.floor(Math.random() * nouns.length)];

    // Capitalize the first letter of the adjective and noun
    const capitalizedAdjective = adjective.charAt(0).toUpperCase() + adjective.slice(1);
    const capitalizedNoun = noun.charAt(0).toUpperCase() + noun.slice(1);

    return `${capitalizedAdjective} ${capitalizedNoun}`;
}

export const validateUrl = (url: string) => {
    if (!url) {
        return 'URL is required'
    }

    if (!url.startsWith('https://')) {
        return 'URL must start with https://'
    }

    return /https?:\/\/\w+\.\w+/.test(url) || 'Invalid URL'
}

interface GenerateShortUrlOptions {
    forceId?: boolean;
}

export const generateShortUrl = (shortlink: Shortlink, domain: string, options: GenerateShortUrlOptions = {}) => {
    let finalId = shortlink.id;
    if (!options.forceId && shortlink.alias) {
        finalId = shortlink.alias;
    }

    if (useEmulators && domain === DEFAULT_DOMAIN) {
        return `http://127.0.0.1:5001/wix-shortlink/europe-west1/visitShortlink?id=${finalId}`
    }
    return `https://${domain}/${finalId}`;
}
