import React, { useMemo } from 'react'
import { Shortlink } from '../models/shared/Shortlink'
import { Changes } from '../models/FirestoreChanges';
import { useInstanceContext } from '../context/InstanceContext';
import { UpdateShortlinkModal } from './UpdateShortlinkModal';
import { DeleteShortlinkModal } from './DeleteShortlinkModal';
import { QRCodeModal } from './QRCodeModal';
import { Button, Dropdown, EmptyState, FormField, IconButton, Image, Search, Table, TableActionCell, TableToolbar, Text, TextButton } from '@wix/design-system';
import { Edit, EmptyTrash, ExternalLink, QR, Statistics } from '@wix/wix-ui-icons-common';
import { generateShortUrl } from '../utils/logic';
import { EMPTY_SPACE_SEARCH_IMAGE } from '../utils/shared/constants';

interface Props {
    showShortLinkForm: () => void
    onShowStats: (id: string) => void
    shortlinks: Shortlink[]
    changes: Changes
}

type OperationType = 'update' | 'delete' | 'qrCode';

interface Operation {
    id: string,
    operation: OperationType
}


interface SortingOptions {
    column: 'Name' | 'Statistics' | 'Created At',
    direction: 'asc' | 'desc'
}

export default function ShortlinkList(props: Props) {
    const [currentOperation, setCurrentOperation] = React.useState<Operation | null>(null);
    const { domain, domainOptions, updateInstanceData } = useInstanceContext();
    const [sorting, setSorting] = React.useState<SortingOptions>({ column: 'Name', direction: 'asc' });
    const [search, setSearch] = React.useState<string>('');

    const finalLinks = useMemo(() => {
        let final = [...props.shortlinks];
        final.sort((a, b) => {
            const first = sorting.direction === 'asc' ? a : b;
            const second = sorting.direction === 'asc' ? b : a;

            if (sorting.column === 'Name') {
                return first.name.localeCompare(second.name);
            } else if (sorting.column === 'Statistics') {
                return first.visited - second.visited;
            } else if (sorting.column === 'Created At') {
                return first.createdAt.getTime() - second.createdAt.getTime();
            }
            return 0;
        })

        if (search) {
            const searchTerms = search.toLowerCase();
            final = final.filter(shortlink => {
                const name = shortlink.name.toLowerCase();
                const targetUrl = shortlink.targetUrl.toLowerCase();
                return name.includes(searchTerms) || targetUrl.includes(searchTerms);
            });
        }

        return final
    }, [sorting, search, props.shortlinks])


    if (props.shortlinks.length === 0) {
        return <EmptyState
            title="No QR Codes Yet"
            subtitle={<Text><TextButton onClick={props.showShortLinkForm}>Add a new QR code</TextButton> to get started</Text>}
            image={<img src="so-really.gif" alt='No shortlinks' />}
        />
    }

    const clearFilters = () => {
        setSearch('');
    }


    const renderOperation = (shortlink: Shortlink) => {
        if (!currentOperation || currentOperation.id !== shortlink.id) {
            return null;
        }

        switch (currentOperation.operation) {
            case 'update':
                return <UpdateShortlinkModal
                    onHide={() => setCurrentOperation(null)}
                    shortlink={shortlink}
                />
            case 'delete':
                return <DeleteShortlinkModal
                    onHide={() => setCurrentOperation(null)}
                    shortlink={shortlink}
                />
            case 'qrCode':
                return <QRCodeModal
                    onHide={() => setCurrentOperation(null)}
                    shortlink={shortlink}
                    shortUrl={generateShortUrl(shortlink, domain, { forceId: true })}
                />
            default:
                return null;
        }
    };

    const renderEmptyFilter = () => (
        <Table.EmptyState
            title="No results found"
            subtitle="No items match your search criteria. Try to search by another keyword."
            image={
                <Image
                    width="120px"
                    height="120px"
                    transparent={true}
                    src={EMPTY_SPACE_SEARCH_IMAGE}
                />
            }
        >
            <TextButton onClick={() => clearFilters()}>Clear Search</TextButton>
        </Table.EmptyState>
    );

    return <>
        <Table
            data={finalLinks}
            onSortClick={(colData, col) => {
                const column = colData.title as any;
                const direction = sorting.column === column && sorting.direction === 'asc' ? 'desc' : 'asc';
                setSorting({ column, direction });
            }}
            columns={[
                {
                    title: 'Name',
                    render: shortlink => shortlink.name,
                    sortable: true,
                    sortDescending: sorting.column === 'Name' ? sorting.direction === 'desc' : undefined,
                },
                {
                    title: 'Short URL',
                    render: shortlink => generateShortUrl(shortlink, domain),
                },
                {
                    title: "QR Code",
                    width: '100px',
                    align: 'center',
                    render: shortlink => <IconButton
                        onClick={() => setCurrentOperation({ id: shortlink.id, operation: 'qrCode' })}
                        priority="secondary"
                    >
                        <QR />
                    </IconButton>
                },
                {
                    title: 'Target URL',
                    render: shortlink => {
                        const longUrl = shortlink.targetUrl.replace(/^https?:\/\//, '').slice(0, 10) + '...';
                        return <a href={shortlink.targetUrl} target='_blank' rel="noreferrer">{longUrl} <ExternalLink /></a>
                    }
                },
                {
                    title: 'Created At',
                    width: '120px',
                    render: shortlink => shortlink.createdAt.toLocaleDateString(),
                    sortable: true,
                    sortDescending: sorting.column === 'Created At' ? sorting.direction === 'desc' : undefined,
                },
                {
                    title: 'Statistics',
                    width: '100px',
                    sortable: true,
                    sortDescending: sorting.column === 'Statistics' ? sorting.direction === 'desc' : undefined,
                    render: shortlink => <Button suffixIcon={<Statistics />} onClick={() => props.onShowStats(shortlink.id)}>{shortlink.visited}</Button>
                },
                {
                    title: '',
                    width: '80px',
                    render: shortlink => <>
                        <TableActionCell
                            secondaryActions={[
                                {
                                    text: 'Update',
                                    icon: <Edit />,
                                    onClick: () => setCurrentOperation({ id: shortlink.id, operation: 'update' }),
                                },
                                {
                                    divider: true,
                                },
                                {
                                    text: 'Delete',
                                    icon: <EmptyTrash />,
                                    skin: 'destructive',
                                    onClick: () => setCurrentOperation({ id: shortlink.id, operation: 'delete' }),
                                }
                            ]}
                        />

                        {renderOperation(shortlink)}
                    </>
                }

            ]}
        >
            <TableToolbar>
                <TableToolbar.ItemGroup>
                    <TableToolbar.Item>
                        <TableToolbar.Title>Your QR Codes</TableToolbar.Title>
                    </TableToolbar.Item>
                </TableToolbar.ItemGroup>
                <TableToolbar.ItemGroup>
                    <TableToolbar.Item>
                        <FormField labelPlacement="left" label="Domain"
                            infoContent="Choose a domain for your shortlink and QR code. Each code always works with all listed domains regardless of what you chose in this view. Want a custom domain? Reach out">
                            <Dropdown
                                size="small"
                                border="round"
                                selectedId={domain}
                                options={domainOptions.map(domain => ({ id: domain, value: domain }))}
                                onSelect={option => updateInstanceData({ preferedDomain: option.id + '' })}
                            />
                        </FormField>
                    </TableToolbar.Item>


                    <TableToolbar.Item>
                        <Search size="small" value={search} onChange={e => setSearch(e.target.value)} onClear={() => setSearch('')} />
                    </TableToolbar.Item>
                </TableToolbar.ItemGroup>
            </TableToolbar>

            {finalLinks.length === 0 && renderEmptyFilter()}
            <Table.Content />
        </Table>

    </>;
}