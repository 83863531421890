import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import QRCode from 'qrcode';
import { Shortlink } from '../models/shared/Shortlink';
import { ColorInput, SegmentedToggle, Text } from '@wix/design-system';

interface QRCodeModalProps {
  onHide: () => void;
  shortlink: Shortlink;
  shortUrl: string;
}

const isValidHexColor = (color: string) => color.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

const QRCodeModal: React.FC<QRCodeModalProps> = (props) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [color, setColor] = useState('#000');
  const [format, setFormat] = useState<'svg' | 'png'>('png');

  useEffect(() => {
    if (!canvasRef.current || !isValidHexColor(color)) {
      return;
    }

    QRCode.toCanvas(canvasRef.current, props.shortUrl, {
      errorCorrectionLevel: 'H',
      width: 200,
      color: {
        dark: color,
        light: '#FFF'
      }
    });
  }, [props.shortUrl, color])

  const downloadQR = async () => {
    if (!canvasRef.current) {
      return;
    }

    try {

      let objectURL;
      if (format === 'svg') {
        const svgString = await QRCode.toString(props.shortUrl, {
          errorCorrectionLevel: 'H',
          type: 'svg',
          color: {
            dark: color,
            light: '#FFF'
          }
        });

        const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
        objectURL = URL.createObjectURL(svgBlob);
      } else {
        objectURL = canvasRef.current.toDataURL("image/png").replace("image/png", "image/octet-stream");
      }

      const downloadLink = document.createElement("a");
      downloadLink.href = objectURL;
      downloadLink.download = `${props.shortlink.name}.${format}`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      URL.revokeObjectURL(objectURL);

    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal show={true} onHide={props.onHide} centered >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.shortlink.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <h4>Customize your QR code and download</h4>
        <label>Customize</label>
        <div className='wrapper d-flex'>
          <div className='flex-1 d-flex flex-column' style={{ gap: '12px' }}>
            <div className='center'>
              <canvas className='m-1' ref={canvasRef} />
            </div>

            <div>
              <Text>Primary Color</Text>
              <ColorInput value={color} onChange={color => setColor(color as string)} />
            </div>

            <div>
              <Text>File Format</Text>
              <SegmentedToggle selected={format} onClick={(_, value) => setFormat(value as any)}>
                <SegmentedToggle.Button value="png">PNG</SegmentedToggle.Button>
                <SegmentedToggle.Button value="svg">SVG</SegmentedToggle.Button>
              </SegmentedToggle>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-end'>
          <Button variant='outline-primary' onClick={props.onHide} className='me-2'>
            Cancel
          </Button>
          <Button variant="primary" onClick={downloadQR}>
            Download
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export { QRCodeModal };