import React, { createContext, useEffect } from "react";
import { InstanceData } from "../models/shared/InstanceData";
import { getRemainingTrialDays } from '../utils/logic';
import { getInstanceData } from "../utils/api";
import { getMaxAllowedLinks } from "../utils/shared/instances";
import { setInstanceData as setInstanceDataApi } from '../utils/api'
import { BEAVER_DOMAIN, DEFAULT_DOMAIN, WIX_DOMAIN } from "../utils/shared/constants";


interface InstanceContext {
    instanceData: Partial<InstanceData>
    onTrial: boolean,
    daysLeft: number,
    trialEnded: boolean,
    trialExpired: boolean,
    loaded: boolean,
    maxLinks: number | null
    domain: string,
    domainOptions: string[],
    updateInstanceData: (instanceUpdate: InstanceDataUpdate) => Promise<void>
}

const instanceContext = createContext<InstanceContext>({
    instanceData: {},
    onTrial: false,
    daysLeft: 0,
    trialEnded: false,
    trialExpired: false,
    loaded: false,
    maxLinks: 5,
    domain: DEFAULT_DOMAIN,
    domainOptions: [DEFAULT_DOMAIN],
    updateInstanceData: async () => { }
})

export const useInstanceContext = () => React.useContext(instanceContext);

interface Props {
    children: React.ReactNode
}

interface InstanceDataUpdate {
    preferedDomain?: string;
}

export const InstaceContextProvider = (props: Props) => {
    const [instanceData, setInstanceData] = React.useState<Partial<InstanceData>>({});
    const [loaded, setLoaded] = React.useState(false);
    const onTrial = !instanceData.vendorProductId;
    const daysLeft = getRemainingTrialDays(instanceData.trialEndsAt || new Date());
    const trialEnded = daysLeft < 1;
    const trialExpired = onTrial && trialEnded;

    useEffect(() => {
        getInstanceData().then(data => {
            setInstanceData(data)
            setLoaded(true)
        });
    }, [])

    let maxLinks = getMaxAllowedLinks(instanceData);
    const domain = instanceData.preferedDomain || DEFAULT_DOMAIN;
    const domainOptions = [];
    if (instanceData.customDomain) {
        domainOptions.push(instanceData.customDomain)
    }
    domainOptions.push(WIX_DOMAIN, BEAVER_DOMAIN);


    const updateInstanceData = async (instanceUpdate: InstanceDataUpdate) => {
        setInstanceData({
            ...instanceData,
            ...instanceUpdate
        })

        await setInstanceDataApi(instanceUpdate)
    }


    return <instanceContext.Provider value={
        {
            instanceData,
            onTrial,
            daysLeft,
            trialEnded,
            trialExpired,
            loaded,
            maxLinks,
            domain,
            domainOptions,
            updateInstanceData,
        }
    }>{props.children}</instanceContext.Provider>
}
