import { InstanceData } from "../../models/shared/InstanceData";
import { PLANS } from "./constants";

const MAX_LINKS_BASE = 5;
const MAX_LINKS_BUSINESS = 25;

export const getMaxAllowedLinks = (instanceData: Partial<InstanceData>): number | null => {
    const planType = instanceData.vendorProductId || instanceData.trialVendorProductId || PLANS.base;
    if (planType === PLANS.business) {
        return MAX_LINKS_BUSINESS;
    } else if (planType === PLANS.enterprise) {
        return null;
    }

    return MAX_LINKS_BASE;
}
