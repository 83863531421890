import { Box, Button, Card, Text } from '@wix/design-system';
import { useInstanceContext } from '../context/InstanceContext';
import { UPGRADE_LINK } from '../utils/shared/constants';
import { Favorite } from '@wix/wix-ui-icons-common';

interface Props {
    className?: string;
}

export default function TrialInfo(props: Props) {
    const { instanceData, onTrial, daysLeft, trialEnded } = useInstanceContext();

    const upgradeTriggered = () => {
        const instanceId = instanceData.instanceId;
        window.location.href = UPGRADE_LINK + instanceId;
    }

    if (!instanceData.instanceId || !onTrial) {
        return null;
    }

    return (
        <Card>
            <Card.Content>
                <Box gap={'24px'} direction='vertical' className='center'>

                    {trialEnded
                        ? <Text>This app comes with 30 day risk free trial.</Text>
                        : <Text>
                            There {daysLeft > 1 ? 'are' : 'is'} <span className='text-primary'>{daysLeft}</span> {daysLeft > 1 ? 'days' : 'day'} left on your <span className='text-primary'>trial</span>!
                        </Text>}
                    <Button skin='premium' onClick={upgradeTriggered} prefixIcon={<Favorite />}>
                        {trialEnded ? 'Start trial' : 'Upgrade'}
                    </Button>
                </Box>
            </Card.Content>
        </Card>
    );
}